import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function Logo(props) {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          resize(width: 340) {
            src
          }
        }
      }
    }
  `);
  return (
    <img
      width="170px"
      src={data.file.childImageSharp.resize.src}
      alt=""
      {...props}
    />
  );
}

export default Logo;
